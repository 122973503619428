import { requests } from "./network";
import { getCollectionTarget, setCollectionObserve } from "../page/collection";
import {
	getTotalRating,
	insertCarousel,
	insertReviewControl,
	insertReviewDetail,
	processRecordedData,
	setInsertTarget,
} from "../page/page-common";
import { getReviews } from "../reviews/reviews";
import { $dqs, gsSession, hexToRgba, returnCitySN, setDelay } from "./utils";

import v from "./variable";
import u from "../user_need/user_need";
import { getProductTarget } from "../page/product";
import { insertRevTab } from "../reviews/window-review";
import { checkGridHigh } from "../reviews/grid";

import "../../css/module/trustoo-common.less";
import "../../css/module/trustoo-mobile.less";
import "../../css/module/trustoo-pc.less";

import "../../css/module/write-reviews.less";
import "../../css/module/buyers-show.less";
import "../../css/trustoo-insert.less";
import { insertBuyersShow } from "../reviews/buyers-show";
import { CartWatcher } from "../page/obseverCart";

if (module.hot) {
	module.hot.accept();
}

//Mark 程序初始
async function initial(data) {
	let layoutClass, layoutReviewCount, reviewsRow;

	// 引入全局变量
	const {
		isActWdoRev,
		globalStyle,
		isHomePage,
		isProPage,
		isCartPage,
		isCollPage,
		hasPyRating,
		hasEcomRt,
	} = v;
	const { pageType, isMobile } = Review;
	// 获取用户自定义需求数据
	await u.getUserNeedData();

	// 引入需求变量
	const {
		isCollReview,
		isCollStartDelay,
		isPageReview,
		reviewsPageCount,
		homeStarDelay,
		isCollRevWord,
	} = u;

	// 数据整理
	Review.is_install = data.is_install;
	Review.is_product_open = data.is_activate_product_rating;
	Review.is_reviews_open = data.is_activate_reviews;
	Review.is_collection_open = data.is_activate_collection_rating;
	Review.is_activate_home_rating = data.is_activate_home_rating;
	Review.is_show_stars_without_review = data.is_show_stars_without_review;
	Review.theme_version = data.theme_version;
	v.userSetting = data;
	v.revHeadType = data.is_hide_ratings === 1 ? 2 : 1;

	// 嵌入遮罩
	if (!$dqs("#trustoo-mask")) {
		document.body.insertAdjacentHTML(
			"beforeend",
			'<div id="trustoo-mask" style="display:none"></div>'
		);
	}

	const userSetting = data;
	Review.lang = Shopify.locale;

	// 确认是否嵌入评论区，并进行相关初始化
	const isInsRev =
		pageType != "collection" || isCollReview || isActWdoRev || isPageReview;
	if (isInsRev) {
		// 确定评论区为list、grid，进行不同初始化
		let isList = false,
			isGrid = false,
			isSplit = false;
		const layout = data.layout;
		if (layout == 1 || layout == 3) {
			layoutClass = "list-";
			reviewsRow = 3;
			isList = true;
			if (layout == 3) {
				isSplit = true;
			}
		} else if (layout == 2) {
			layoutClass = "grid-";
			reviewsRow = 5;
			isGrid = true;
			v.gridLoadedShowImg = 0;
			v.gridShowImgCount = 0;
		} else if (layout == 3) {
		}

		layoutReviewCount = userSetting.reviews_per_page_number;
		if (reviewsPageCount) {
			layoutReviewCount = reviewsPageCount;
		}

		// 获取客户端ip
		returnCitySN(v);
		// 覆盖全局变量
		Object.assign(v, {
			layoutClass,
			layoutReviewCount,
			reviewsRow,
			globalStyle,
			isGrid,
			isList,
			isSplit,
		});
	}

	// 确认是否需要多语言，请求多语言数据
	if (isInsRev || isCollRevWord) {
		await langLoaded(data);
	}
	// 添加初始样式
	addInitSty(data, isInsRev);

	// 是否存在pagefly和ecom的集成的块
	if (hasPyRating || hasEcomRt) {
		if (
			$dqs(".pagefly-review-widget-sealapp,.custom-vstar-review-widget") ||
			(v.isHomePage && data.is_activate_home_reviews === 1)
		) {
			if (!shopifyObj.productId) v.isMixReview = true;
			v.isRevOpen = true;
		} else if (v.isProPage && data.is_activate_reviews === 1) {
			v.isRevOpen = true;
		}
		setInsertTarget(2);
		await getTotalRating();
	}

	//Mark Product相关
	else if (isProPage) {
		v.isProRatingOpen = data.is_activate_product_rating === 1;

		if (data.layout == 1) {
			if (data.is_transparent == 1 || isMobile) {
				data.is_transparent = 0;
			}
			userSetting.card_bg_color = hexToRgba(
				data.card_bg_color,
				data.is_transparent
			);
		} else {
			userSetting.card_bg_color = data.card_bg_color;
		}

		insertBuyersShow();

		// 是否为链接邀评
		if (location.search.indexOf("req=review") !== -1) {
			v.isLinkReqRev = true;
		}

		if (data.is_activate_reviews === 1) v.isRevOpen = true;

		//Dev 开发时预览本地-防止插入星星
		await getTotalRating();

		//Mark Collection相关
	} else if (isCollPage || u.otherPageColl) {
		let isCollOpen = data.is_activate_collection_rating !== 2;
		if (isCollOpen) {
			if (isCollStartDelay) {
				await setDelay(2000);
			}
			await getCollectionTarget();
			if (Review.collTarget.length === 0) {
				if (v.isSetObserver) {
					setCollectionObserve();
				}
			} else {
				setInsertTarget(2);
			}
		}
		if (userSetting.is_activate_reviews === 1 && isCollReview) {
			v.isMixReview = true;
			getReviews({});
		}
	} else if (isHomePage) {
		//Mark Home页相关
		// home页商品详情插入星星和评论区
		let onlyPro,
			productHandle = "",
			proTarget,
			collTarget = [];
		let { productId, productImages, productName } = v;
		let isHomeReview = false;

		v.isHomeRatingOpen = data.is_activate_home_rating === 1;

		if (data.is_activate_home_reviews === 1) {
			v.isRevOpen = true;
			v.isHomeReview = isHomeReview = true;
			let [reqErr, data] = await requests.getOnlyOneProduct();
			onlyPro = data;
			if (reqErr === null && onlyPro) {
				productId = onlyPro.product_id;
				productName = onlyPro.product_title;
				productImages = onlyPro.image_src;
				productHandle = onlyPro.product_handle;
			}
		}

		if (data.is_activate_home_rating === 1 || isHomeReview) {
			await getProductTarget();
			proTarget = Review.proTarget;
			if (data.is_activate_home_rating === 1) {
				if (homeStarDelay) {
					await setDelay(homeStarDelay);
				}

				//home页商品列表插入星星
				await getCollectionTarget();
				collTarget = Review.collTarget;
				let collLen = collTarget.length;
				if (collLen === 1) {
					if (collTarget[0] === proTarget) collTarget.pop();
					else setInsertTarget(2);
				} else if (collLen !== 0) {
					(collTarget.includes(proTarget) ||
						collTarget[0].contains(proTarget)) &&
						(Review.proTarget = proTarget = null);
					setInsertTarget(2);
				} else {
					if (v.isSetObserver) {
						setCollectionObserve();
					}
				}
			}
			if (!productId) {
				if (proTarget) {
					productName = Review.proTarget.textContent.replace(/\n/g, "").trim();
					if (productName) {
						let [reqErr, proInfo] = await requests.getProductInfo(
							encodeURIComponent(productName)
						);
						if (reqErr === null && proInfo) {
							productId = proInfo.product_id;
							productImages = proInfo.image_src;
							productHandle = proInfo.product_handle;
						}
					}
				}
			}

			if (data.is_activate_home_reviews === 1) {
				if (!onlyPro && !productId) {
					v.isMixReview = true;
				}
			}
			if (u.isHomeAllReviews) {
				v.isMixReview = true;
			}
			Review.productId = productId;
			Object.assign(v, {
				productId,
				productName,
				productImages,
				productHandle,
			});
			if (productId || v.isMixReview) {
				await getTotalRating();
			}
		}
		if (
			userSetting.card_carousel_setting &&
			userSetting.card_carousel_setting.is_home_active === 1
		) {
			const selector =
				(Object.keys(u.custom_carousel_info).length &&
					u.custom_carousel_info.selector) ||
				"footer";
			const position =
				(Object.keys(u.custom_carousel_info).length &&
					u.custom_carousel_info.position) ||
				"beforebegin";
			insertCarousel($dqs(selector), position);
		}
	} else if (isCartPage) {
		const pvProId = gsSession("get", "tt_cart_product_id");
		if (pvProId) {
			v.cartWatcher = new CartWatcher();
			await v.cartWatcher.init();
			processRecordedData("add_to_cart", v.cartWatcher.storedCartToken(), true);
		}
		if ($dqs(".custom-vstar-review-widget")) {
			v.isMixReview = true;
			insertReviewControl(null, {});
		}
	} else if (isPageReview) {
		// Mark 其他页面嵌入评论区
		v.isMixReview = true;
		let tarInfo = {};
		if (v.allRev) {
			tarInfo.tarNode = v.allRev;
			tarInfo.position = "afterbegin";
		}
		v.isInsertRev = true;
		getReviews(tarInfo);
	}
	if (isActWdoRev) {
		if (!v.isInsertRev) {
			insertRevTab();
		}
		const sidetab_setting = data.sidetab_setting;
		const styTxt = `#vstar-tab{ background-color:${sidetab_setting.sidetab_background_color}; color:${sidetab_setting.sidetab_font_color} } `;
		globalStyle.insertAdjacentHTML("beforeend", styTxt);
	}
}

// // 插入内联样式
// function insertGlobalStyle() {

// }

//Mark 多语言设置
async function langLoaded(data) {
	const langList = ["ar", "bg", "cs", "da", "de", "el", "en", "es", "ar", "bg"]
		.concat(["cs", "da", "de", "el", "en", "es", "et", "fi", "fr", "he"])
		.concat(["hr", "hu", "id", "it", "ja", "ka", "lt", "lv", "ms", "nl"])
		.concat(["no", "pl", "pt", "ro", "sk", "sl", "sv", "th", "tr", "zh-TW"]);
	langList.push("zh-CN", "pt-PT");
	let isCurLang = true;
	// 店铺是否设置跟随翻译
	if (data.auto_switch_language === 1) {
		const lang = Shopify.locale;
		if (["zh-TW", "zh-CN", "pt-PT"].includes(lang)) Review.lang = lang;
		else Review.lang = lang.split("-")[0];
		if (!langList.includes(Review.lang)) {
			Review.lang = data.language;
		} else {
			if (Review.lang !== data.language) {
				isCurLang = false;
			}
		}
	} else {
		Review.lang = data.language;
	}

	let reqErr,
		lang,
		isHasLang = typeof vstar_lang_json !== "undefined";
	if (!isHasLang || !isCurLang || v.isSettingUpdate) {
		[reqErr, data] = await requests.getLang();
		if (reqErr === null) {
			lang = JSON.parse(data.lang_json);
		} else {
			if (isHasLang) {
				lang = vstar_lang_json;
			}
		}
	} else {
		reqErr = null;
		lang = vstar_lang_json;
	}
	if (reqErr === null && lang) {
		lang.show_full_review && (v.ellipsisText = lang.show_full_review);
		if (v.shop_id === 45159514262 && Review.lang === "es") {
			lang.reply = "Respuesta";
		}
		Object.assign(v, { lang });
	}
}

// Mark 添加相关样式
function addInitSty(data, isInsRev) {
	let gobStyTxt = `.vstar-star{--star-color:${v.userSetting.star_color};
	--nostar-color:${v.userSetting.nostar_color};}`;
	if (isInsRev) {
		const fc = data.font_color;
		let revBoxColor = data.box_bg_color;
		// 为了Sense主题设置对应的样式
		const themeStoreId = v.theme_store_id;
		if (themeStoreId === 1356 || themeStoreId === 1567) {
			if (revBoxColor === "#FFFFFF") revBoxColor = "rgba(0,0,0,0)";
		}

		let borderRadius = data.border_radius + "px";
		const cardShadow =
			data.card_shadow === "Dark"
				? "1px 1px 6px rgba(0, 0, 0, 0.1)"
				: "0px 0px 3px rgba(0, 0, 0, 0.2)";
		// 评论区各元素背景、字体颜色设置
		gobStyTxt += `
        #reviews-wrapper,#noreviews-wrapper,#vstar-reviews,
        #vstar-noreviews{
          background-color: ${revBoxColor};
          --font-color: ${fc};
          --font-color-o4: ${hexToRgba(fc, 0.4)};
          --font-color-o7: ${hexToRgba(fc, 0.7)};
          --font-color-o8: ${hexToRgba(fc, 0.8)};
					--font-size:${data.font_size}px;
          --bg-color:${revBoxColor};
          --badge-text-color:${data.badge_text_color};
          --badge-color:${data.verified_badge_color};
          --card-color:${data.card_bg_color};
          --box-bg-color:${revBoxColor};
          --button-radius:${borderRadius};
          --button-color:${data.button_color};
          --button-bd-color:${data.button_bg_color};
          --button-border-color:${data.button_border_color};
					--line-color:${data.dividing_line_color};
					--reply-bg-color:${data.reply_bg_color};
					--avatar-text-color:${data.avatar_text_color};
					--avatar-bg-color:${data.avatar_bg_color};
        }
				.trustoo-widget{--sprites-image-url:url(https://${
					v.staticBaseUrl
				}/static/images/sprites/trustoo_sprites.png?${v.constructTime});}
        #vstar-window-review,#vstar-window-review #reviews-wrapper,
        #vstar-window-review #vstar-reviews{background-color: ${revBoxColor.slice(
					0,
					7
				)};}
        #vstar-reviews.pending>* {
          opacity: 0 !important;
        }
				#vstar-reviews #reviews-body .grid-review{
					box-shadow: ${cardShadow};
				}
				#vstar-reviews #reviews-body .reviews-text{
					font-size:${data.font_size}px;
				}
				.product-icon-list i{
					font-size:20px
				}
        #trustoo-allinone i,
        .write-review-container i{font-size:16px}
        #reviews-head i{font-size:20px}
        `;

		if (data.qa_colors) {
			const colors = data.qa_colors.split(",");
			gobStyTxt += `.trustoo-widget{--tab-title-color:${colors[0]};
					--tab-title-line-color:${colors[1]};
					--question-color:${colors[3]};
					--question-color-o8: ${hexToRgba(colors[3], 0.8)};
				}`;
		}

		// grid布局对应的样式
		if (v.isGrid) {
			gobStyTxt += `.grid-review{background-color: ${data.card_bg_color}}
      #vstar-reviews #reviews-body{margin-top:16px !important}`;
		}
	}
	if (typeof u.custom_style_info.css_style_code !== "undefined") {
		gobStyTxt += u.custom_style_info.css_style_code;
	}

	if (u.vstarCustomSty) {
		gobStyTxt += u.vstarCustomSty;
	}
	v.globalStyle.insertAdjacentHTML("beforeend", gobStyTxt);
}

//Mark 导入外部样式
function importStyle() {
	let baseUrl = Review.baseUrl;
	if (v.env === "production") {
		baseUrl = "cdn.vstar.app";
	}
	if (v.env !== "local") {
		var vstarStyle = document.createElement("link");
		vstarStyle.rel = "stylesheet";
		const link =
			`https://${baseUrl}/static/dist/css/trustoo-reviews.min.css?` +
			v.constructTime;
		vstarStyle.href = link;
		vstarStyle.onerror = function () {
			this.href = link;
		};
		vstarStyle.onload = async function () {
			if (!v.isCssLoaded) {
				v.isCssLoaded = true;
				const sideTab = $dqs("#vstar-tab");
				sideTab && (sideTab.style.display = "flex");
				const proStarWidget = $dqs(".product-icon-list.vstar-star");
				proStarWidget && (proStarWidget.style.display = "block");
				setTimeout(() => {
					if (v.reviewsDom && v.firstPageShow) {
						v.reviewsDom.classList.remove("pending");
						$dqs(".tt-review-list", v.reviewsDom).style.opacity = 1;
					}
				}, 0);
			}
			if (v.isFirstExecute && v.reviewsDom && v.isGrid) {
				checkGridHigh($dqs(".tt-review-list", v.reviewsDom), true);
			}
		};
		document.head.appendChild(vstarStyle);
	} else {
		v.isCssLoaded = true;
	}

	var indexStyle = document.createElement("link");
	indexStyle.rel = "stylesheet";
	indexStyle.href = `https://${baseUrl}/static/css/index.css`;

	var spriteStyle = document.createElement("link");
	spriteStyle.rel = "stylesheet";
	spriteStyle.href = `https://${baseUrl}/static/css/trustoo-sprite.min.css`;

	document.head.appendChild(indexStyle);

	document.head.appendChild(spriteStyle);
}
// 导入第三方脚本
function importScript() {
	let scrArr = [];
	if (!$dqs(".tt-review-detail-js")) {
		let src =
			`https://${v.staticBaseUrl}/static/js/tt-review-detail.js?` +
			v.constructTime;
		if (v.env === "local") {
			src = `../../js/tt-review-detail.js?${new Date().getTime()}`;
		}
		scrArr.push(src);
	}

	scrArr.forEach(item => {
		let scr = document.createElement("script");
		scr.src = item;
		scr.setAttribute("defer", true);
		if (item.indexOf("tt-review-detail") !== -1) {
			scr.className = "tt-review-detail-js";
			scr.onload = function () {
				insertReviewDetail();
			};
		}

		document.head.appendChild(scr);
	});
}

export { initial, langLoaded, importStyle, importScript };

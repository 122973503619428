import v from "../common/variable";
import { requests } from "../common/network";
import { $dqs, $dqsa, scrollTo } from "../common/utils";
import u from "../user_need/user_need";
import { starEmbeddedAdaptation } from "./page-common";

//Mark 获取产品页嵌入目标
async function getProductTarget() {
	const { isProPage, isHomePage } = v;
	const { customProTarSel } = u;
	let themeSelector;

	themeSelector = getProductNodeClassNameSelector();
	let tarList = [],
		proTarget,
		isFound = false;
	const customRating = $dqsa(
		".custom-vstar-rating-widget,.pagefly-star-rating-sealapp,.ecom-star-rating-sealapp"
	);
	if (customRating.length !== 0) {
		tarList = customRating;
		isFound = true;
	}
	if (customProTarSel && !isFound) {
		proTarget = $dqs(customProTarSel);
		if (proTarget) {
			isFound = true;
			Review.proType = "custom";
			Review.proSel = customProTarSel;
			Review.proTarget = proTarget;
		}
		if (isHomePage && proTarget) return;
	}
	// if (!u.isOpenProTarMatch) return
	if (!isFound) {
		tarList = $dqsa(themeSelector.main);
		if (tarList.length === 0 && !themeSelector.isAll) {
			tarList = $dqsa(themeSelector.mainAll);
		}
		if (tarList.length !== 0) isFound = true;
		Review.proType = "main";
		Review.proSel = themeSelector.main;
	}
	if (!isFound) {
		tarList = $dqsa(themeSelector.other1);
		Review.proType = "other1";
		Review.proSel = "`" + themeSelector.other1 + "`";
		if (tarList.length === 0) {
			tarList = $dqsa(themeSelector.other2);
			Review.proType = "other2";
			Review.proSel = "`" + themeSelector.other2 + "`";
		}
		if (tarList.length === 0) {
			tarList = $dqsa(themeSelector.other3);
			Review.proType = "other3";
			Review.proSel = "`" + themeSelector.other3 + "`";
		}
	}
	if (tarList.length === 2) {
		proTarget = Array.from(tarList).find(i => i.offsetParent !== null);
	} else if (tarList.length !== 0) {
		proTarget = tarList[0];
	}
	if (!proTarget) {
		if (isProPage) {
			await requests
				.sendErrorMsg(
					3,
					`产品页评价嵌入失败，店铺地址为：${window.location.href}`
				)
				.then(res => {
					if (res[1]) {
						const selector = starEmbeddedAdaptation(5, res[1].selector);
						if (selector) {
							Review.proSel = selector;
							proTarget = $dqs(selector);
						}
					}
				});
		}
	}
	if (proTarget) {
		Review.proTarget = proTarget;
	}
}
//Mark 获取产品页嵌入目标类名选择器
function getProductNodeClassNameSelector() {
	let themeSelector = {};
	let theme_store_id = Review.theme.theme_store_id;
	//Brooklyn Simple Venture Debut Minimal
	var main1 = ".product-single__title";
	//Dawn Narrative Sense Crave Craft
	var main2 = ".product__title";
	//Supply
	var main3 = '.grid-item>h1.h2[itemprop="name"]';
	//Boundless
	var main4 = '.grid__item>h1.h2[itemprop="name"]';

	let select;
	let mainAll = [main1, main2, main3, main4].join(",");
	switch (theme_store_id) {
		case 730:
		case 578:
		case 775:
		case 796:
		case 380: {
			select = main1;
			break;
		}
		case 887:
		case 829:
		case 1356:
		case 1363:
		case 1368: {
			select = main2;
			break;
		}
		case 679: {
			select = main3;
			break;
		}
		case 766: {
			select = main4;
			break;
		}
		default: {
			themeSelector.isAll = true;
			select = mainAll;
		}
	}
	// $dqsa('.product-single__title,.product__title,.grid-item>h1.h2[itemprop="name"],.grid__item>h1.h2[itemprop="name"]')
	themeSelector.main = select;
	themeSelector.mainAll = mainAll;
	// themeSelector.mainAll = [main1,main2,main3,main4].join(',')

	var other1 = ".product__title .product__title--template";
	var other2 = ".ProductMeta__Title";
	var other3 = ".product_title";
	var other4 = ".tt-product-single-info .tt-title";
	var other5 = ".product-block-list__item .product-meta__title";
	var other31 = ".product-meta>.product-meta__title";

	var other6 = ".product-title";
	var other7 = ".gt_atom-cZGCOCvfkBpcRgQ_productTitle";
	var other8 = ".pr_title";
	var other9 = ".prd-block_title";
	var other28 = ".product-detail__title";

	var other10 = ".productInfo h1";
	var other11 = ".product_name";
	var other33 = ".product-name";
	var other12 = ".product-description-header";

	// var other23 = ".product__text"

	var other14 = ".layout-column-half-right>.title";
	var other15 = ".product-page-info__title>h1";

	// var other16 = ".sc-ksHpcM"

	// var other18 = ".sc-gyElHZ"
	// var other19 = ".sc-gUQvok"
	// var other20 = ".sc-cjrPHo"

	var other21 = "h1.gt_heading";
	var other22 = "#popup_cart_title";
	// var other23 = ".sc-gnnDb"
	var other24 = ".apb_product_detail_information_title";
	var other25 = ".product-area__details__title";
	var other26 = ".product-info>h3.title30";
	var other27 = ".gt_product-content>h2";
	// var other29 = "span[data-pf-type]"
	var other30 = ".prod__title";
	var other32 = ".productInfo>h2";
	var other34 = ".product__section-title>h1"; //13263470649

	// var other_1_0 = 'h1[data-product-type="title"]'
	// var other_1_1 = "h3[data-product-type]"
	// var other_1_2 = "h2[data-product-type]"
	var other_1_3 = ".page-header h1.title"; // 63292309749
	var other_1_4 = ".title-detail"; // 60738208001

	var other_1_6 = ".title>h1"; // 61318856930
	var other_1_7 = ".title-row>h1.title"; // 63483412727
	var other_1_8 = "h2.product-page--heading"; // 58211336366
	var other_1_9 = ".section-header__title.product-titre"; // 58393657502
	var other_1_10 = ".block.type-title";
	var other_1_11 = "h2.product-details-product-title"; //63761285353
	var other_1_12 = "h1.product-info__title"; //28773384245
	var other_1_13 = ".product-detail-part>h2[itemprop='name']"; //64080249081
	var other_1_14 = "h2.single_product__title"; //64080249081
	var other_1_15 = "#product-description h1.h2"; //56854577195
	var other_1_16 = "h1.product-item-caption-title.-product-page"; //61732913323
	var other_1_17 = "h1.product-title"; //61732913323
	var other_1_18 = "h2[data-attribute='productTitle']";
	var other_1_19 = ".productView-title";

	var other_3_1 = "h1.product__section-title"; // 57518784671
	var other_3_2 = "h1.product-single__title-text"; // 57322897545
	var other_3_3 = 'h1[itemprop="name"]'; // 61318856930
	var other_3_4 = ".proBoxInfo>h1"; // 57448333466
	var other_3_5 = ".product-header";
	var other_3_6 = "h1.title-primary"; // 55832150085
	var other_3_7 = "[data-product-description-container]>h2.h1";
	var other_3_8 = ".uppercase";
	var other_3_9 = ".col-md-12>.title-sale";
	var other_3_10 = ".shg-product-title-component";
	var other_3_11 = ".t4s-product__title";
	var other_3_12 = ".bls__product-title";

	let productTargetArr = [];
	productTargetArr.push(
		other1,
		other2,
		other3,
		other4,
		other5,
		other6,
		other7,
		other8,
		other9,
		other10,
		other11,
		other12,
		other14,
		other15,
		other21,
		other22,
		other24,
		other25,
		other26,
		other27,
		other28,
		other30,
		other31,
		other32,
		other33,
		other34
	);
	let other1Selector = [
		other_1_3,
		other_1_4,
		other_1_6,
		other_1_7,
		other_1_8,
		other_1_9,
		other_1_10,
		other_1_11,
		other_1_12,
		other_1_13,
		other_1_14,
		other_1_15,
		other_1_16,
		other_1_17,
		other_1_18,
		other_1_19,
	].join(",");
	let other3Selector = [
		other_3_1,
		other_3_2,
		other_3_3,
		other_3_4,
		other_3_5,
		other_3_6,
		other_3_7,
		other_3_8,
		other_3_9,
		other_3_10,
		other_3_11,
		other_3_12,
	].join(",");
	let other2Selector = productTargetArr.join(",");
	themeSelector.other1 = other1Selector;
	themeSelector.other2 = other2Selector;
	themeSelector.other3 = other3Selector;

	return themeSelector;
}

// Mark 产品页评价点击跳转tab评论区
function proClickTabActive() {
	const shop_id = v.shop_id;
	if (u.get_move_review_info.is_tab_review === 1 && !v.isWdoRevOpen) {
		if (shop_id === 61646700724 && v.themeId === 129829568692) {
			activeCls = "t4s-active";
			let active = $dqs(
				`.panel.sp-tab.${activeCls},.t4s-tab-wrapper>.t4s-tab-content.${activeCls}`
			);
			if (!active)
				active = $dqs(".t4s-tab-wrapper:nth-child(2)>.t4s-tab-content");
			else active.classList.remove(activeCls);
			const target = $dqs(
				"#tab_pr_reivew,.t4s-tab-wrapper:nth-child(4)>.t4s-tab-content"
			);
			target.classList.add(activeCls);
			if (v.isMobile) {
				$dqs(".sp-tab-content", active).style.display = "none";
				$dqs("#tab_pr_reivew .sp-tab-content").style.cssText = "";
			} else {
				$dqs(
					`.tab_title_block.active,.t4s-d-inline-flex>a.t4s-active`
				).classList.remove(activeCls);
				$dqsa(".tab_title_block,.t4s-tab-wrapper")[1].classList.add(activeCls);
				active.style.display = "none";
				target.style.display = "block";
			}
		} else if (shop_id === 67745513752 && v.themeId === 137959506200) {
			const tabNodes = $dqs('[data-id="1670265256519"] .module');
			const curTab = $dqs("[data-control].opend", tabNodes);
			if (curTab !== null) {
				curTab.classList.remove("opend");
				curTab.nextElementSibling.style.display = "none";
			}
			const targetTab = $dqs('[data-control][data-index="5"]', tabNodes);
			if (targetTab !== null) {
				targetTab && targetTab.classList.add("opend");
				targetTab.nextElementSibling.style.display = "block";
			}
		} else if (shop_id === 69381783840 && v.themeId === 144923328800) {
			const nodes = $dqsa(".product-simple-tab .tab-pane");
			const tabs = $dqsa(".nav-item .nav-link");
			nodes[0].className = "tab-pane";
			nodes[2].className = "tab-pane active show";
			tabs[0].className = "nav-link";
			tabs[1].className = "nav-link active show";
		}
	}
}

function pageflyProRatingClk() {
	if (v.isProPage) {
		const ratingWrp = $dqs(
			`.pagefly-star-rating-sealapp[product-id="${v.productId}"]`
		);
		if (ratingWrp) {
			ratingWrp.style.cursor = "pointer";
			ratingWrp.onclick = function () {
				v.reviewsDom && scrollTo(v.reviewsDom);
			};
		}
	}
}

export {
	getProductTarget,
	getProductNodeClassNameSelector,
	proClickTabActive,
	pageflyProRatingClk,
};

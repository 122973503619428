import v from "../common/variable";
import { qaRequests, requests } from "../common/network";
import { getTotalStarString } from "../page/page-common";
import {
	imgLoad,
	$dqs,
	setLoading,
	$dqsa,
	getMobileSystem,
	setToast,
	debounce,
	hexToRgba,
	setCopy,
	getObjectURL,
} from "../common/utils";
import { handleHashchange } from "./reviews";
import svg from "../common/svg";

let imageUploader = null,
	videoUploader = null,
	writeRev = null,
	uploadType = 0;

const uploadedImageLimit = 5;

// 输入信息时防抖检查是否符合上传条件
const inputCheck = debounce(isCouldSend, 500);

//Mark 添加评论弹窗
async function addReviewWindow(d) {
	let close = "",
		discountTip = "";

	const { lang, productImages, productName, isNoUploadImg } = v;
	let isDelPowered = v.userSetting.is_del_powered === 1;

	let extraClass = "";
	if (typeof Review !== "undefined" && ["ar", "he"].includes(Review.lang)) {
		extraClass = " trustoo-rtl";
	}

	if (
		/^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
		v.isMobile &&
		window.innerHeight < 667
	) {
		v.globalStyle.textContent += `#trustoo-allinone>.tt-write-content {
			padding-bottom: 100px;
	}`;
	}

	v.globalStyle.textContent += `
	#trustoo-allinone,#success-window{
		--button-bd-color: ${d.review_button_background_color};
		--button-text-color: ${d.review_button_text_color};
	}
    #trustoo-allinone .form-submit[disabled]{background-color:${hexToRgba(
			d.review_button_background_color,
			0.3
		)} !important}
  `;
	const revInpHtml = getInputsHtml(d, lang);

	close = `<div class="window-close tt-write_review_close">
        </div>`;

	const discountSetting = d.discount_setting;
	if (
		discountSetting.discount_for_photo_reviews == 1 &&
		discountSetting.discount_value &&
		(discountSetting.discount_for_reviews_form === 1 ||
			(discountSetting.discount_for_reviews_form === 2 &&
				typeof isEmailWrite !== "undefined"))
	) {
		const ds = v.lang.all_in_one_from.discount_setting;
		for (let i in ds) {
			ds[i] = ds[i].replace(
				"{{Discount_amount}}",
				discountSetting.discount_value
			);
		}
		let text = "";
		if (discountSetting.discount_incentive_conditions === 1) {
			text = ds.tip_3;
		} else if (discountSetting.discount_incentive_conditions === 2) {
			text = ds.tip_1;
		}
		discountTip = `<div class="discount-tip">${text}</div>`;
	}

	let window_head = `
  <div class="window-head">
    ${close}
    <span class="head-title">${lang.all_in_one_from.write_reviews}</span>
  </div>`;
	let product_image = "";
	if (productImages) {
		product_image = `<div class="product-image" style="background-image:url('${productImages}')"></div>`;
	}
	let product_star = `<div class="tt-write-rating">
	<div class="tt-rating-title">${v.lang.you_rating}</div>
	<div class="vstar-star" data-type="${d.rating_icon_type}">
	${getTotalStarString(0)} 
	</div>

  </div>`;
	let uploaderHtml = "";
	let videoHtml = "";
	if (d.write_review_form === 1) {
		videoHtml =
			d.enabled_add_video === 1
				? ` 
    <div class="upload-or">${lang.all_in_one_from.or}</div>
      <div class="tt-uploader tt-video-uploader" style="display : ${
				isNoUploadImg ? "none" : "flex"
			}">
          <input type="file" class="upload-input" tabindex="-1"
          accept=".mp4,.mkv,.mov,.avi,.flv,.ts,.ps,.mxf,.lxf,.gxf,.3gp,.webm,.mpg,.quicktime">
          <span class="tt-upload_image"></span>
          <span class="upload-image-text">${
						lang.all_in_one_from.add_video
					}</span>
          <span class="images-num">0/1</span>
    </div>
  `
				: "";
		uploaderHtml = ` <div class="upload-area">
				<div class="tt-uploader tt-image-uploader" style="display : ${
					isNoUploadImg ? "none" : "flex"
				}">
						<input type="file" multiple class="upload-input"
						tabindex="-1" accept=".gif,.jpeg,.jpg,.png">
						<span class="tt-upload_image"></span>
						<span class="upload-image-text">${lang.add_photo}</span>
						<span class="images-num">0/5</span>
				</div>
				${videoHtml}
			</div>`;
	}

	const require = d.require_feedback === 1 ? "require" : "";
	const isHidden = d.show_feedback === 1 ? "" : "hidden";
	const feedback = `<div class="feedback-area inline" ${isHidden}>
	<div class="tt-input-title">${lang.all_in_one_from.review}</div>
	<textarea input-type="content" maxLength="${v.newRevCharLimit}"  class="big-input user-input ${require}" ></textarea>
	<div class="feedback-count"><span>0/${v.newRevCharLimit}</span></div>
	</div><div class="error-tip feedback-tip" ${isHidden}></div>`;

	let window_form = `<form action="" class="review-message">
	${feedback}
	${uploaderHtml}
    ${revInpHtml}

		${discountTip}
    <button disabled class="form-submit" type="button">${lang.submit}</button>
  </form>`;

	let window_product = `<div class="window-product">${product_image}<div class="product-right"></div></div>`;

	let revWdoPower = "";
	if (!isDelPowered) {
		revWdoPower = `<div class="trustoo-power"> Powered by <a class="redirect" href="https://www.trustoo.io/" target="_blank">Trustoo.io</a><span class="line"></span><a class="tt-power-cw" href="https://www.channelwill.com/" target="_blank">${svg.cw}</a></div>`;
	}
	let review_window = `<div id="trustoo-allinone" class="trustoo-widget ${extraClass} trustoo-write-review" >
  <div class="tt-write-content"> ${
		window_head + window_product + product_star + window_form + revWdoPower
	}</div></div>`;

	$dqs("#trustoo-mask").insertAdjacentHTML("beforeEnd", review_window);
	writeRev = $dqs("#trustoo-allinone");
	imageUploader = $dqs(".tt-image-uploader", writeRev);

	if (d.show_feedback === 1)
		//textarea初始字体不一样，需要修改字体，保持风格一致
		$dqs("textarea", writeRev).style.fontFamily = window.getComputedStyle(
			$dqs("input", writeRev)
		).fontFamily;

	// 限制产品名称显示长度
	if (productName) {
		let proName = $dqs(".product-right");
		if (productName.length > 100) {
			proName.innerHTML = productName.substring(0, 100) + "...";
		} else {
			proName.innerHTML = productName;
		}
	}

	setEvent(writeRev);
	if (v.isLinkReqRev) {
		document.body.classList.add("trustoo-open");
		$dqs("#trustoo-mask").style.display = "flex";
		$dqs(".trustoo-write-review").style.display = "block";
	}
}

// Mark 设置事件
function setEvent(writeRev) {
	writeRev.addEventListener(
		"touchmove",
		e => {
			e.stopPropagation();
		},
		false
	);

	// X图标点击，关闭弹窗
	$dqs(".window-close", writeRev).onclick = function () {
		reviewWindowInit();
		if (v.isMobile) {
			window.history.replaceState(
				{},
				document.title,
				window.location.href.replace("#tt-write-reviews-open", "")
			);
		}
		closeWindow();
	};

	// 产品图片大小适配
	if (v.productImages) {
		imgLoad(v.productImages).then(img => {
			const w = img.width,
				h = img.height;
			const n = $dqs(".product-image", writeRev);
			if (w >= h) {
				n.style.backgroundSize = "auto 100%";
			} else {
				n.style.backgroundSize = "100% auto";
			}
		});
	}

	// 星星滑动闪烁
	let stars = $dqsa(".star-item", writeRev);
	fnShow(v.ratingVal);
	for (let i = 1; i <= stars.length; i++) {
		stars[i - 1].index = i;
		if (v.isMobile) {
			stars[i - 1].onclick = function () {
				//拇指触击,改变ratingVal值点亮星星
				v.ratingVal = this.index;
				fnShow(this.index);
				isCouldSend();
			};
		} else {
			stars[i - 1].onmouseover = function () {
				//鼠标经过点亮星星。
				fnShow(this.index); //传入的值为正，就是finalnum
			};
			// stars[i - 1].onmouseout = function () {
			// 	//鼠标离开时星星变暗
			// 	fnShow(0); //传入值为0，finalnum为ratingVal,初始为0
			// };
			stars[i - 1].onclick = function () {
				//鼠标点击,同时会调用onmouseout,改变ratingVal值点亮星星
				v.ratingVal = this.index;
				isCouldSend();
			};
		}
	}
	if (!v.isMobile) {
		$dqs(".tt-write-rating .vstar-star", writeRev).onmouseleave = function () {
			//鼠标离开时星星变暗
			fnShow(0); //传入值为0，finalnum为ratingVal,初始为0
		};
	}

	let inputList = $dqsa(".user-input", writeRev);
	const lang = v.lang;
	const isMobile = v.isMobile;

	// 移动端文本框输入兼容
	if (isMobile) {
		let mobileSystem = getMobileSystem();
		if (mobileSystem === "ios") {
			writeRev.style.paddingBottom = "70px";
		}
		let bheight = window.getComputedStyle(writeRev).getPropertyValue("height");
		let fun = function () {
			if (mobileSystem == "android")
				$dqs("#trustoo-allinone").style.overflow = "hidden";
			let t = setInterval(() => {
				let aheight = window
					.getComputedStyle(writeRev)
					.getPropertyValue("height");
				if (aheight != bheight) {
					if (mobileSystem == "android") {
						$dqs("#trustoo-allinone").style.overflow = "scroll";
						$dqs("#trustoo-allinone").scrollTop = 230;
					}
					clearInterval(t);
				}
			}, 200);
		};
		$dqsa("#trustoo-allinone .user-input").forEach(item =>
			item.addEventListener("click", fun)
		);
		$dqsa("#trustoo-allinone .user-input").forEach(item =>
			item.addEventListener("focus", fun)
		);
	}
	const uploadWrapper = $dqs(".upload-area", writeRev);
	if (uploadWrapper) {
		uploadWrapper.onclick = e => {
			if (
				[
					"tt-delete_image",
					"hover-image",
					"image-delete tt-mobile_delete_image",
				].includes(e.target.className)
			) {
				const item = e.target.closest(".uploaded-item");
				let name = item.dataset.name;
				let urlIndex = v.mediaUrl.findIndex(item => item.name == name);
				v.mediaUrl.splice(urlIndex, 1);
				item.remove();

				if (item.dataset.type === "image") {
					if (v.imgUploadedCount === uploadedImageLimit) {
						toggleUploaderShow(1);
					}
					v.imgUploadedCount--;
				} else if (item.dataset.type === "video") {
					toggleUploaderShow(3);
				}

				$dqs(".images-num", writeRev).innerHTML = `${v.imgUploadedCount}/5`;
			}
		};
	}

	//Mark 评论区文本框事件
	inputList.forEach(item => {
		item.onblur = function () {
			let tipDom = this.parentNode.nextElementSibling;
			if (this.value == "" && this.classList.contains("require")) {
				tipDom.innerHTML = `<div class="input-tip"><span class="tt-error_tip"></span><span>${lang.required}</span>
                </div>`;
				tipDom.classList.add("display");
				this.classList.add("error-border");
			}
		};
		item.addEventListener("input", function () {
			const tipDom = this.parentNode.nextElementSibling;
			tipDom.innerHTML = "";
			tipDom.classList.remove("display");
			this.classList.remove("error-border");
			if (this.parentNode.classList.contains("email-area")) {
				const reg =
					/^[a-zA-Z0-9_-]+(\.[A-Za-z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if (!reg.test(this.value) && this.value) {
					tipDom.innerHTML = `<div class="input-tip"><span class="tt-error_tip"></span><span>${lang.email_error}</span>
            </div>`;
					tipDom.classList.add("display");
					this.classList.add("error-border");
				} else if (!this.value) {
					tipDom.innerHTML = "";
					tipDom.classList.remove("display");
					this.classList.remove("error-border");
				}
			} else if (this.classList.contains("big-input")) {
				textNumChange();
			}

			inputCheck();
		});
	});

	// 图片上传
	if (imageUploader) {
		$dqs("input", imageUploader).onchange = function () {
			uploadFile(this.files, "image")
				.then(() => (this.value = ""))
				.catch(() => {});
		};
	}

	videoUploader = $dqs(".tt-video-uploader .upload-input", writeRev);
	if (videoUploader) {
		videoUploader.onchange = function () {
			const file = this.files[0];
			const types = this.getAttribute("accept")
				.split(",")
				.map(it => {
					return it.slice(1);
				});
			const type = file.name.split(".").pop();
			if (!types.includes(type.toLowerCase())) {
				setToast(v.lang.error_tip);
				return false;
			}
			const mbSize = parseInt(file.size / (1024 * 1024));
			if (mbSize >= 200) {
				setToast("The file cannot exceed 200MB");
				return false;
			}
			// $dqsa(".upload-area>div", writeRev).forEach(
			// 	i => (i.style.display = "none")
			// );
			toggleUploaderShow(4);
			uploadFile(this.files, "video").then(() => (this.value = ""));
		};
	}

	// 点击按钮提交评论
	$dqs(".form-submit", writeRev).onclick = function () {
		this.setAttribute("disabled", true);
		this.innerHTML = `${lang.submitting}`;
		let loadingSize = 12;
		if (v.isMobile) {
			loadingSize = 20;
		}
		setLoading(this, loadingSize);
		this.setAttribute("disabled", true);
		sendNewReview();
	};
}
//Mark 获取输入框的html代码
function getInputsHtml(d, lang) {
	let revInpHtml = `
    <div class="name-area inline">
		<div class="tt-input-title">${lang.name}</div>
      <input type="text" maxLength="50"  input-type="author" class="small-input user-input require" ">
    </div>
    <div class="error-tip name-tip"></div>`;
	let require, isHidden;

	require = d.require_email === 1 ? "require" : "";
	isHidden = d.show_email === 1 ? "" : "hidden";
	revInpHtml += `<div class="email-area inline" ${isHidden}>
	<div class="tt-input-title">${lang.email}</div>
    <input type="text" input-type="author_email" class="small-input user-input ${require}">
    </div><div class="error-tip email-tip" ${isHidden}></div>`;

	require = d.require_phone === 1 ? "require" : "";
	isHidden = d.show_phone === 1 ? "" : "hidden";
	revInpHtml += `<div class="phone-area inline" ${isHidden}>
	<div class="tt-input-title">${lang.phone}</div>
    <input type="text" input-type="author_phone" class="small-input user-input ${require}">
    </div><div class="error-tip phone-tip" ${isHidden}></div>`;

	return revInpHtml;
}

//Mark 文件上传
async function uploadFile(files, type) {
	const { mediaUrl } = v;
	let isCheckCouldSendRev = false;
	let imgNum = $dqsa(".uploaded-item[data-type=image]").length;
	let imgUploadArea = $dqs(".tt-uploader");
	let len = files.length;
	let name = "";

	if (type === "image" && !Object.keys(v.uploadImgInfo).length) {
		const res = await requests.getPolicyToken();
		v.uploadImgInfo = res[1];
	}

	if (files.length + imgNum >= 5 && type === "image") {
		len = 5 - imgNum;
		toggleUploaderShow(2);
		// imageUploader.style.display = "none";
	}
	for (let i = 0; i < len; i++) {
		let url = "";
		let file = files[i];
		if (type === "image") {
			// $dqsa(".tt-video-uploader,.upload-or", writeRev).forEach(
			// 	i => (i.style.display = "none")
			// );
			v.imgUploadedCount++;
			url = getObjectURL(file);
			name = file.name;
			requests.uploadImg(file).then(res => {
				let name = res[1].name;
				if (res[0] === null) {
					$dqs(`.uploaded-item[data-name="${name}"]>.loader`).remove();
					if ($dqsa(`.uploaded-item>.loader`).length === 0) {
						isCouldSend(true);
					}
					mediaUrl.push({
						name: name,
						url: "https://img.trustoo.io/" + res[1].key,
						resource_type: 1,
					});
				} else {
					if (v.imgUploadedCount === uploadedImageLimit) {
						toggleUploaderShow(1);
					}
					v.imgUploadedCount--;
					$dqs(`.uploaded-item[data-name="${name}"]`).remove();
					$dqs(".images-num").innerHTML = `${v.imgUploadedCount}/5`;
					setToast("Failed to upload image");
				}
			});
			uploadType = 1;
		} else if (type === "video") {
			uploadType = 2;
			requests
				.getUploadVideoUrl()
				.then(res => {
					const { upload_url, domain } = res[1];
					const uid = res[1].video_service_uid;
					requests
						.uploadVideo(upload_url, file)
						.then(() => {
							const link = domain + "/" + uid;
							const imgUrl = link + "/thumbnails/thumbnail.jpg";
							v.mediaUrl.push({ url: link + "/iframe", resource_type: 2 });
							const videoEle = $dqs(
								".uploaded-item[data-type=video]",
								writeRev
							);
							const image = new Image();
							image.onload = () => {
								videoEle.insertAdjacentHTML("afterbegin", svg.video);
							};
							image.src = imgUrl;
							videoEle.style.backgroundImage = `url(${imgUrl})`;
							setImageBackground(videoEle, imgUrl, () => {
								videoEle.classList.remove("uploading");
								$dqs(`.loader`, videoEle).remove();
								isCouldSend(true);
							});
						})
						.catch(() => {
							failUploadVideo();
						});
				})
				.catch(() => {
					failUploadVideo();
				});
		}

		let imageItem = document.createElement("div");
		imageItem.className = "uploaded-item";
		imageItem.setAttribute("data-type", type);

		if (type === "image") {
			if (name) {
				imageItem.setAttribute("data-name", name);
			}
			$dqs(
				".tt-image-uploader .images-num"
			).innerHTML = `${v.imgUploadedCount}/5`;
			setImageBackground(imageItem, url);
		} else if (type === "video") {
			imageItem.classList.add("uploading");
		}

		imageItem.innerHTML = `<div class="hover-image">
                      <div class="tt-delete_image"></div>
                    </div>
                    `;

		imgUploadArea.before(imageItem);
		setLoading(imageItem, 24);
		if (!isCheckCouldSendRev) {
			isCheckCouldSendRev = true;
			isCouldSend(false);
		}
		if (v.isMobile) {
			let del = document.createElement("div");
			del.className = "image-delete tt-mobile_delete_image";
			imageItem.appendChild(del);
		}
	}
}

function failUploadVideo() {
	$dqs(".uploaded-item[data-type=video]", writeRev).remove();
	toggleUploaderShow(3);
	setToast(v.lang.error_tip);
	videoUploader.value = "";
}

/**
 * 上传器的显示切换
 * @param {number} type - 1为显示图片上传器，2.为隐藏图片上传器，3为显示视频上传器，四为隐藏视频上传器
 */
function toggleUploaderShow(type) {
	switch (type) {
		case 1:
			imageUploader.style.display = "flex";
			break;
		case 2:
			imageUploader.style.display = "none";
			break;
		case 3:
			$dqsa(".tt-video-uploader,.upload-or", writeRev).forEach(
				it => (it.style.display = "flex")
			);
			break;
		case 4:
			$dqsa(".tt-video-uploader,.upload-or", writeRev).forEach(
				it => (it.style.display = "none")
			);
			break;
		default:
			break;
	}
}

//Mark 开始发送评论
function sendNewReview() {
	if (v.isSendLock) {
		setToast("Please comment again after 15 seconds");
		return;
	}
	// 1为写评论。2为写问题
	let type = 0,
		selector = "";
	if (writeRev.getAttribute("write-qa")) {
		type = 2;
	} else {
		type = 1;
	}
	if (type === 1) {
		selector = ".inline:not([hidden]) .user-input";
	} else if (type === 2) {
		selector = '.user-input:not([input-type="author_phone"])';
	}
	let request = null;
	const params = {};
	var inpVal = Array.from($dqsa(selector));

	if (type === 1) {
		request = requests.uploadReview;
		inpVal.forEach(i => {
			const type = i.getAttribute("input-type");
			if (i.value) {
				params[type] = i.value;
			}
		});

		if (v.isLinkReqRev) {
			if (typeof isEmailWrite === "undefined") {
				params.review_source = 1;
			}
		} else {
			params.review_source = 0;
		}
		if (v.ip) {
			params.ip = v.ip;
			params.author_country = v.loc;
		}
	} else if (type === 2) {
		request = qaRequests.addQA;
		const fields = ["question", "questioner", "questioner_email"];
		inpVal.forEach((it, index) => {
			params[fields[index]] = it.value;
		});
		params.questioner_country = v.loc;
	}

	request(params, uploadType, v.mediaUrl)
		.then(res => {
			if (res[0] === null) {
				const data = res[1];

				const lang = v.lang;
				submitButtonInit();
				reviewWindowInit();
				document.body.classList.add("trustoo-open");
				$dqs("#trustoo-allinone").style.display = "none";
				const sw = $dqs("#success-window");
				sw.style.display = "block";
				const wdoTitle = $dqs(".window-title", sw);
				const wdoContent = $dqs(".window-content", sw);
				if (type === 1) {
					wdoTitle.textContent = lang.success_message;
					wdoContent.textContent = lang.submitted_successfully;
				} else if (type === 2) {
					wdoTitle.textContent = lang.question_and_answer.thanks;
					wdoContent.textContent = lang.question_and_answer.submitted;
				}
				if (data && data.discount_code && type === 1) {
					const ds = v.lang.all_in_one_from.discount_setting;
					$dqs(".window-discount", sw).style.display = "flex";
					wdoTitle.textContent = ds.title;
					wdoTitle.style.marginTop = "0";
					$dqs(".window-content", sw).textContent = ds.subtitle;
					$dqs(".discount-code", sw).textContent = data.discount_code;
				}
				if (v.isLinkReqRev) {
					window.history.replaceState(
						{},
						"",
						location.href.replace("?req=review", "")
					);
					v.isLinkReqRev = false;
				}
				v.isSendLock = true;
				setTimeout(() => (v.isSendLock = false), 1500);
			} else {
				submitButtonInit();
				let msg = "";
				if (res[0].code === -2) {
					msg = res[0].error;
				} else {
					msg = v.lang.error_tip;
				}
				setToast(msg, "1%");
				// sendErrorMsg(6, '发送评论失败，原因如下：' + err)
			}
		})
		.catch(() => {
			submitButtonInit();
			setToast(`${v.lang.error_tip}`, "1%");
			// sendErrorMsg(6, '发送评论失败，原因如下：' + err)
		});
}

// 发送评论后按钮初始化
function submitButtonInit() {
	const { lang } = v;
	let submit = $dqs(".form-submit");
	// submit.querySelector('.loader').remove()
	submit.innerHTML = `${lang.submit}`;
	submit.removeAttribute("disabled");
	//   submit = null
}

// 评论弹窗初始化
function reviewWindowInit() {
	Object.assign(v, {
		imgUploadedCount: 0,
		uploadedIndex: 0,
		mediaUrl: [],
	});
	writeRev.removeAttribute("write-review");
	writeRev.removeAttribute("write-qa");
	$dqsa(".user-input", writeRev).forEach(i => (i.value = ""));
	$dqsa(".error-tip", writeRev).forEach(i => (i.innerHTML = ""));
	let error = $dqsa(".error-border", writeRev);
	if (error.length) {
		error.forEach(item => item.classList.remove("error-border"));
	}

	if (v.writeForm === 1) {
		$dqsa(".uploaded-item", writeRev).forEach(item => item.remove());
		$dqs(".tt-image-uploader .images-num", writeRev).innerText = "0/5";
		if (uploadType === 2) {
			$dqs(".tt-video-uploader .images-num", writeRev).innerText = "0/1";
		}
		$dqsa(".tt-uploader,.upload-or", writeRev).forEach(
			i => (i.style.display = "flex")
		);
	}

	const revCount = $dqs(".feedback-count>span", writeRev);
	revCount && (revCount.textContent = "0/" + v.newRevCharLimit);

	const submitButton = $dqs(".form-submit", writeRev);
	submitButton.setAttribute("disabled", true);
	v.ratingVal = 0;
	fnShow(0);
}

// 文本框文字数量变化事件
function textNumChange() {
	let reviewInput = $dqs(".big-input");
	let textNum = reviewInput.value.length;
	$dqs("#trustoo-allinone .feedback-count>span").textContent =
		textNum + "/" + v.newRevCharLimit;
}

// 星星值变化事件
function fnShow(num) {
	let lis = $dqsa("#trustoo-allinone .star-item");
	let finalnum = num || v.ratingVal; //如果传入的num为0，则finalnum取ratingVal的值
	for (let i = 0; i < lis.length; i++) {
		if (i < finalnum) {
			lis[i].classList.remove("nostar");
		} else {
			lis[i].classList.add("nostar");
		}
	}
	if (v.ratingVal !== 0) {
		isCouldSend();
	}
}
// 是否可以发评论
function isCouldSend() {
	const n = writeRev;
	let type = 0,
		selector = "";
	if (writeRev.getAttribute("write-qa")) {
		type = 2;
	} else {
		type = 1;
	}
	if (type === 1) {
		selector = ".inline:not([hidden]) .user-input.require";
	} else if (type === 2) {
		selector = '.user-input:not([input-type="author_phone"])';
	}
	let inputList = $dqsa(selector, n);
	let submitButton = $dqs(".form-submit", n);
	let isAllInput = true;
	//文本框是否为空
	inputList.forEach(inputItem => {
		if (!inputItem.value && inputItem.parentNode.style.display !== "none") {
			isAllInput = false;
		}
	});
	let isUploadedImg = $dqsa(`.uploaded-item>.loader`, n).length === 0;
	if (
		isAllInput &&
		isUploadedImg &&
		$dqsa(".input-tip", n).length == 0 &&
		!(type === 1 && v.ratingVal === 0)
	) {
		submitButton.removeAttribute("disabled");
	} else {
		submitButton.setAttribute("disabled", true);
	}
}
//Mark 评论成功弹窗
function successSend() {
	const { lang } = v;
	const image_url = "https://cdn.vstar.app/static/images/Vector.png";
	let window_title = `
    <div class="window-title">
        ${lang.success_message}
    </div>`;
	let window_close = `<div class="window-close tt-write_review_close"></div>`;
	imgLoad(image_url);
	let discount = "";
	let window_content = `<div class="window-content">${lang.submitted_successfully}</div>`;
	let window_button = `<div class="window-button"><button >${lang.continue_shopping}</button></div>`;
	// d.get_discount_copy = ' Copy'

	discount = `<div class="window-discount" style="display:none">
          <span class="discount-code"></span>
          <span class="discount-copy">${v.lang.all_in_one_from.discount_setting.copy}</span>
        </div>`;
	let window = `<div id="success-window" class="trustoo-widget">${
		window_close + window_title + window_content + discount + window_button
	}</div>`;
	const sw = $dqs("#success-window");
	$dqs("#trustoo-mask").insertAdjacentHTML("beforeend", window);
	$dqs("#success-window .window-close").onclick = closeWindow;
	$dqs(".window-button button", sw).onclick = closeWindow;

	$dqs(".discount-copy", sw).onclick = function () {
		setCopy($dqs(".discount-code", sw));
	};
}
// 关闭添加评论弹窗
function closeWindow() {
	const sw = $dqs("#success-window");
	$dqsa(".window-discount", sw).forEach(i => (i.style.display = "none"));
	$dqs(".window-title", sw).style.cssText = "";
	$dqs("#trustoo-mask").style.display = "none";
	$dqs("#trustoo-allinone").style.display = "none";
	sw.style.display = "none";
	document.body.classList.remove("trustoo-open");
	window.removeEventListener("hashchange", handleHashchange);
}

function setImageBackground(node, url, fn) {
	imgLoad(url)
		.then(img => {
			if (fn) {
				fn();
			}
			if (img.width >= img.height) {
				node.style.backgroundSize = "100% auto";
			} else {
				node.style.backgroundSize = "auto 100%";
			}
			node.style.backgroundImage = `url(${url})`;
		})
		.catch(() => {
			if (fn) {
				fn();
				node.style.backgroundImage = `url(../../images/video_default.png)`;
			}
		});
}
export {
	addReviewWindow,
	sendNewReview,
	submitButtonInit,
	reviewWindowInit,
	textNumChange,
	successSend,
	closeWindow,
	inputCheck,
};

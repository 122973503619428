const svg = {
	verified: `<svg class="trustoo-badge-icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.30236 10.7724C5.45276 10.9239 5.64913 11 5.84629 11C6.04267 11 6.23982 10.9239 6.39022 10.7724L11.775 5.32765C12.075 5.02399 12.075 4.532 11.775 4.22834C11.4742 3.92389 10.9871 3.92389 10.6871 4.22834L5.84629 9.1223L3.31288 6.56115C3.01286 6.25748 2.52581 6.25748 2.22501 6.56115C1.925 6.86481 1.925 7.35758 2.22501 7.66125L5.30236 10.7724Z"/>
	</svg>`,
	close(color) {
		return `<svg style="width:20px;height:20px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99968 5.95621L1.25984 0.216371C1.12066 0.0819462 0.934254 0.00756417 0.740764 0.00924555C0.547273 0.0109269 0.362184 0.0885372 0.225361 0.225361C0.0885372 0.362184 0.010927 0.547273 0.00924558 0.740764C0.0075642 0.934254 0.0819463 1.12066 0.216371 1.25984L5.95547 6.99968L0.216371 12.7395C0.147807 12.808 0.0934101 12.8894 0.056285 12.9789C0.01916 13.0685 3.431e-05 13.1644 4.612e-08 13.2614C-3.42178e-05 13.3583 0.0190236 13.4543 0.0560853 13.5439C0.0931471 13.6334 0.147487 13.7148 0.216002 13.7834C0.284518 13.8519 0.365867 13.9063 0.455405 13.9435C0.544944 13.9806 0.640917 13.9997 0.737847 13.9997C0.834777 13.9998 0.930764 13.9807 1.02033 13.9437C1.10989 13.9066 1.19128 13.8523 1.25984 13.7837L6.99968 8.04316L12.7395 13.7837C12.8081 13.8523 12.8895 13.9067 12.9791 13.9438C13.0687 13.9809 13.1647 14 13.2616 14C13.3586 14 13.4546 13.9809 13.5442 13.9438C13.6338 13.9067 13.7152 13.8523 13.7837 13.7837C13.8523 13.7152 13.9067 13.6338 13.9438 13.5442C13.9809 13.4546 14 13.3586 14 13.2616C14 13.1647 13.9809 13.0687 13.9438 12.9791C13.9067 12.8895 13.8523 12.8081 13.7837 12.7395L8.04316 6.99968L13.7837 1.25984C13.9221 1.12137 13.9998 0.933605 13.9997 0.737847C13.9997 0.542089 13.9218 0.354375 13.7834 0.216002C13.6449 0.0776292 13.4571 -6.91476e-05 13.2614 4.61754e-08C13.0656 6.92399e-05 12.8779 0.0779003 12.7395 0.216371L6.99968 5.95547V5.95621Z" fill="
    ${color}"/>
    </svg> 
    `;
	},
	star: `<svg class="trustoo-rating-icon" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0L12.8214 6.11672L19.5106 6.90983L14.5651 11.4833L15.8779 18.0902L10 14.8L4.12215 18.0902L5.43493 11.4833L0.489435 6.90983L7.17863 6.11672L10 0Z"/>
    </svg>
    `,
	store(color = "#666666") {
		return `<svg width="14" height="14" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.48243 8.25H1.56692V6H0.798534V9H9.25082V6H8.48243V8.25ZM9.94237 2.85C9.86553 2.625 9.78869 2.475 9.71185 2.25C9.25082 1.5 8.86663 0.75 8.48243 0H1.56692L1.49008 0.15C1.41325 0.3 1.33641 0.375 1.33641 0.525C1.10589 0.975 0.875373 1.35 0.644856 1.8L0.414339 2.25C0.260661 2.55 0.106983 2.85 0.0301443 3.15C-0.0466947 3.525 0.0301443 3.9 0.183822 4.2C0.491178 4.8 1.10589 5.175 1.7206 5.25C2.33531 5.325 3.1037 5.025 3.4879 4.5C3.87209 4.95 4.40997 5.25 5.02468 5.25C5.63939 5.25 6.17726 4.95 6.56146 4.5C7.02249 5.1 7.86772 5.4 8.55927 5.175C9.32766 5.025 9.86553 4.35 9.94237 3.6C10.0192 3.375 10.0192 3.075 9.94237 2.85ZM8.71295 4.35C8.32875 4.575 7.71404 4.5 7.40669 4.275C7.02249 3.975 6.86881 3.45 6.94565 3H6.17726C6.33094 3.525 6.02358 4.125 5.56255 4.35C5.02468 4.65 4.33313 4.425 4.02577 3.975C3.87209 3.675 3.87209 3.3 3.87209 3H3.1037C3.1037 3.375 3.1037 3.675 2.87319 3.975C2.64267 4.425 1.95112 4.65 1.49008 4.425C1.02905 4.2 0.721695 3.6 0.798534 3.075C0.875373 2.85 0.952212 2.625 1.10589 2.4C1.33641 1.95 1.56692 1.575 1.87428 1.125C1.95112 1.05 2.02796 0.9 2.02796 0.825L2.1048 0.75H7.94456C8.25191 1.35 8.63611 1.875 8.94347 2.475C9.09714 2.7 9.17398 2.925 9.25082 3.15V3.375C9.25082 3.75 9.0203 4.125 8.71295 4.35Z" fill="${color}"/>
    </svg>
    `;
	},
	hook() {
		return `<svg width="14" height="10" viewBox="0 0 14 10" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.95902 6.78757L11.8702 0.242483C12.0341 0.0871857 12.256 0 12.4874 0C12.7187 0 12.9406 0.0871857 13.1046 0.242483L13.7423 0.84642C13.824 0.92377 13.8888 1.01571 13.933 1.11696C13.9772 1.21821 14 1.32677 14 1.43642C14 1.54606 13.9772 1.65462 13.933 1.75587C13.8888 1.85712 13.824 1.94906 13.7423 2.02641L5.59196 9.74524C5.55018 9.78491 5.50431 9.82047 5.45505 9.85138C5.28683 9.9625 5.08298 10.0139 4.879 9.99675C4.67502 9.97957 4.48384 9.89483 4.33876 9.75731L0.257673 5.89228C0.176006 5.81493 0.111202 5.72299 0.0669826 5.62174C0.0227634 5.52049 0 5.41193 0 5.30228C0 5.19264 0.0227634 5.08408 0.0669826 4.98283C0.111202 4.88158 0.176006 4.78964 0.257673 4.71229L0.895425 4.10835C1.05937 3.95305 1.28129 3.86587 1.51262 3.86587C1.74395 3.86587 1.96587 3.95305 2.12981 4.10835L4.95902 6.78757Z"
    fill="#2A2A37" />
    </svg>`;
	},
	prePage(color) {
		return `<svg style="width:13px;height:13px" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84 2.066V1.031c0-.09-.104-.14-.173-.084L.629 5.662a.427.427 0 0 0 0 .674l6.038 4.716a.107.107 0 0 0 .173-.085V9.932a.217.217 0 0 0-.082-.169L1.936 6l4.822-3.765a.217.217 0 0 0 .082-.169z" fill="${color}"/></svg>`;
	},
	nextPage(color) {
		return `<svg style="width:13px;height:13px" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.398 5.663L.36.947a.107.107 0 0 0-.172.085v1.035c0 .065.03.128.081.169L5.091 6 .269 9.765a.213.213 0 0 0-.081.169v1.035c0 .09.103.14.172.084l6.038-4.715a.428.428 0 0 0 0-.675z" fill="${color}" fill-opacity=".85"/></svg>`;
	},
	helpful(cls) {
		return `
		<svg width="14" height="14" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg" class="tt-helpful ${cls}">
			<g id="Frame">
			<path id="Vector" d="M66.7821 43.8307C68.5673 35.8557 69.814 30.0876 70.5244 26.5149C72.3119 17.5201 67.6701 9.16699 58.4702 9.16699C50.1744 9.16699 46.0082 13.2897 43.5515 21.5305L43.4885 21.7712C42.0138 28.4376 39.7588 33.6053 36.7739 37.3029C35.2207 39.2318 33.2649 40.7981 31.0433 41.8922C28.8216 42.9863 26.3877 43.5818 23.9119 43.6371L21.5584 43.6875C15.9461 43.8078 11.459 48.4278 11.459 54.0871V88.2295C11.459 95.1904 17.0564 100.834 23.9623 100.834H68.2763C77.3788 100.834 85.5039 95.0793 88.5919 86.4477L97.7975 60.7112C100.14 54.1627 96.7731 46.9394 90.2774 44.5778C88.9178 44.0835 87.4822 43.8307 86.0355 43.8307H66.7821Z" />
			</g>
		</svg>
		`;
	},
	unhelpful(cls) {
		return `
		<svg width="14" height="14" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg" class="tt-unhelpful ${cls}">
			<g id="Frame">
			<path id="Vector" d="M66.7821 66.1693C68.5673 74.1443 69.814 79.9124 70.5244 83.4851C72.3119 92.4799 67.6701 100.833 58.4702 100.833C50.1744 100.833 46.0082 96.7103 43.5515 88.4695L43.4885 88.2288C42.0138 81.5624 39.7588 76.3947 36.7739 72.6971C35.2207 70.7682 33.2649 69.2019 31.0433 68.1078C28.8216 67.0137 26.3877 66.4182 23.9119 66.3629L21.5584 66.3125C15.9461 66.1922 11.459 61.5722 11.459 55.9129L11.459 21.7705C11.459 14.8096 17.0564 9.16634 23.9623 9.16633L68.2763 9.16633C77.3788 9.16633 85.5039 14.9207 88.5919 23.5523L97.7975 49.2888C100.14 55.8373 96.7731 63.0606 90.2774 65.4222C88.9178 65.9165 87.4822 66.1693 86.0355 66.1693L66.7821 66.1693Z" />
			</g>
			</svg>
		`;
	},
	// 排序图标
	sort(color = "#202123") {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
		<path d="M10 1.00024C10.0001 1.07086 9.98523 1.1407 9.95626 1.2051C9.92729 1.2695 9.88493 1.32699 9.83199 1.37374L5.33187 5.3737C5.24042 5.45506 5.12227 5.5 4.99986 5.5C4.87746 5.5 4.75931 5.45506 4.66785 5.3737L0.167731 1.37374C0.118681 1.33014 0.0786984 1.27731 0.0500671 1.21826C0.0214358 1.15921 0.00471592 1.0951 0.00086218 1.02959C-0.00299156 0.964078 0.00609628 0.898449 0.0276068 0.83645C0.0491174 0.774451 0.0826294 0.717296 0.126229 0.668248C0.169829 0.6192 0.222664 0.579219 0.281716 0.550589C0.340767 0.521958 0.40488 0.505239 0.470394 0.501386C0.535907 0.497532 0.601538 0.506619 0.66354 0.528129C0.725541 0.549639 0.782699 0.583149 0.831749 0.626748L4.99986 4.33171L9.16798 0.626748C9.23994 0.562557 9.32895 0.520568 9.42425 0.505852C9.51956 0.491135 9.61708 0.504318 9.70506 0.543811C9.79303 0.583304 9.8677 0.647416 9.92004 0.728409C9.97237 0.809403 10.0001 0.903813 10 1.00024Z" fill="${color}"/>
	</svg>`;
	},
	multiPhoto() {
		return `
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Frame" clip-path="url(#clip0_19222_33518)">
			<g id="Vector" filter="url(#filter0_d_19222_33518)">
			<path d="M15.9499 13.6124V5.04154C15.9499 3.71237 14.8957 2.6582 13.5665 2.6582H5.04154C3.71237 2.6582 2.6582 3.71237 2.6582 5.04154V13.6124C2.6582 14.9415 3.71237 15.9957 5.04154 15.9957H13.6124C14.8957 15.9499 15.9499 14.8957 15.9499 13.6124ZM17.9665 6.87487V14.254C17.9665 16.3165 16.2707 18.0124 14.2082 18.0124H6.82904C6.55404 18.0124 6.41654 18.3332 6.59987 18.5165C7.0582 19.0207 7.69987 19.3415 8.47904 19.3415H14.6207C17.2332 19.3415 19.3415 17.2332 19.3415 14.6207V8.47904C19.3415 7.7457 19.0207 7.0582 18.5165 6.59987C18.2874 6.41654 17.9665 6.59987 17.9665 6.87487Z" fill="white"/>
			</g>
			</g>
			<defs>
			<filter id="filter0_d_19222_33518" x="-1.3418" y="-1.3418" width="24.6836" height="24.6836" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset/>
			<feGaussianBlur stdDeviation="2"/>
			<feComposite in2="hardAlpha" operator="out"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19222_33518"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19222_33518" result="shape"/>
			</filter>
			<clipPath id="clip0_19222_33518">
			<rect width="22" height="22" fill="white"/>
			</clipPath>
			</defs>
		</svg>
		`;
	},
	qa(color = "#000") {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 2.48437C13.4531 2.48437 16.25 5.28125 16.25 8.73438C16.25 11.6562 14.1719 14.2188 11.3281 14.8438L10.7812 14.9531L10.5 15.4375L10.0156 16.2656L9.54688 15.4375L9.26562 14.9531L8.71875 14.8438C5.84375 14.25 3.75 11.6719 3.75 8.73438C3.75 5.28125 6.54688 2.48437 10 2.48437ZM10 1.23438C5.85938 1.23438 2.5 4.59375 2.5 8.73438C2.5 12.3438 5.0625 15.375 8.46875 16.0781L10.0312 18.7812L11.5938 16.0781C14.9688 15.3281 17.5 12.3281 17.5 8.73438C17.5 4.59375 14.1406 1.23438 10 1.23438ZM10 3.73438C8.60938 3.73438 7.48438 4.875 7.48438 6.25H8.75C8.75 5.5625 9.3125 4.98438 10 4.98438C10.6875 4.98438 11.25 5.54688 11.25 6.23438C11.25 6.57812 11.1094 6.89062 10.8906 7.10938L10.125 7.89062C9.67188 8.34375 9.39062 9 9.39062 9.6875V11.25H10.6406V10C10.6406 9.07812 10.9219 8.6875 11.375 8.21875L11.9375 7.64062C12.2969 7.28125 12.5156 6.78125 12.5156 6.23438C12.5 4.85938 11.375 3.73438 10 3.73438ZM9.375 13.75H10.625V12.5H9.375V13.75Z" fill="${color}" />
</svg>`;
	},
	cw: `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="tt-cw">
	<rect x="1.53711" y="1.39551" width="8.99951" height="8.99951" fill="white"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M3.32765 2.73501e-06C2.8905 -0.000558075 2.45754 0.0851314 2.05355 0.252163C1.64957 0.419194 1.28251 0.664286 0.973399 0.973399C0.664286 1.28251 0.419194 1.64957 0.252163 2.05355C0.0851314 2.45754 -0.000558075 2.8905 2.73501e-06 3.32765V8.67235C2.73501e-06 9.5549 0.350594 10.4013 0.97465 11.0254C1.59871 11.6494 2.44511 12 3.32765 12H8.67235C9.55409 11.9998 10.3997 11.6497 11.0236 11.0266C11.6475 10.4035 11.9986 9.55835 12 8.67661V3.32765C11.9989 2.44658 11.6484 1.60192 11.0254 0.978908C10.4023 0.355895 9.55768 0.00539091 8.67661 0.00426349L3.32765 2.73501e-06ZM6 10C6.79698 9.99949 7.57568 9.76122 8.23648 9.31568C8.89728 8.87014 9.41013 8.23758 9.70942 7.49894H7.8202C7.4845 7.90174 7.03062 8.18877 6.5228 8.31938C6.01498 8.45 5.47894 8.4176 4.99055 8.22676C4.50216 8.03592 4.08616 7.69631 3.80143 7.256C3.51669 6.81569 3.37766 6.29698 3.40399 5.77329C3.43032 5.2496 3.62069 4.74746 3.94816 4.33793C4.27562 3.92841 4.72359 3.63224 5.22866 3.49136C5.73373 3.35047 6.27032 3.372 6.76248 3.55291C7.25464 3.73381 7.67743 4.06491 7.97103 4.49936H9.70942C9.43743 3.82626 8.98741 3.23985 8.4076 2.80297C7.82778 2.36609 7.14002 2.0952 6.41801 2.01935C5.696 1.94349 4.96696 2.06552 4.30901 2.37235C3.65106 2.67919 3.08899 3.15927 2.68305 3.76115C2.2771 4.36303 2.04257 5.06402 2.0046 5.78901C1.96662 6.514 2.12662 7.23565 2.46746 7.87665C2.8083 8.51765 3.31713 9.05383 3.93942 9.42773C4.56172 9.80163 5.27402 9.99916 6 9.99915V10Z" fill="#444444"/>
	</svg>
	`,
	video: `<svg class="tt-video-icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <g filter="url(#filter0_d_5903_67423)">
    <path d="M18 4C10.2806 4 4 10.2806 4 18C4 25.7194 10.2806 32 18 32C25.7194 32 32 25.7194 32 18C32 10.2806 25.7194 4 18 4ZM18 29.8462C11.4685 29.8462 6.15385 24.5315 6.15385 18C6.15385 11.4685 11.4685 6.15385 18 6.15385C24.5315 6.15385 29.8462 11.4685 29.8462 18C29.8462 24.5315 24.5315 29.8462 18 29.8462ZM16.4794 11.7054C16.3187 11.5886 16.1289 11.5185 15.9308 11.5029C15.7328 11.4873 15.5343 11.5267 15.3573 11.6169C15.1803 11.7071 15.0317 11.8444 14.9279 12.0138C14.8241 12.1832 14.7692 12.378 14.7692 12.5766V24.0114C14.7694 24.2099 14.8245 24.4046 14.9283 24.5738C15.0322 24.743 15.1808 24.8802 15.3578 24.9703C15.5347 25.0603 15.7332 25.0997 15.9311 25.084C16.129 25.0684 16.3188 24.9983 16.4794 24.8815L24.3409 19.1642C24.4784 19.0643 24.5903 18.9332 24.6674 18.7818C24.7446 18.6304 24.7848 18.4629 24.7848 18.2929C24.7848 18.123 24.7446 17.9554 24.6674 17.804C24.5903 17.6526 24.4784 17.5216 24.3409 17.4217L16.4794 11.7054Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_5903_67423" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5903_67423"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5903_67423" result="shape"/>
    </filter>
  </defs>
</svg>`,
};

export default svg;

import v from "../common/variable";
import { getTotalStarString } from "../page/page-common";
import { $dqs, $dqsa } from "../common/utils";
import { sgPage } from "./review-pager";
import svg from "../common/svg";
import { setReviewBody } from "./reviews-body";
import { sortPopupHtml } from "./reviews";
import { insertBuyersShow } from "./buyers-show";

//Mark 构造评论区头部
function setReviewHead() {
	const { lang, userSetting, revHeadType } = v;
	const reviews = v.isWdoRevOpen ? v.wdoRev : v.reviews;
	const isAllRev = v.isMixReview || v.isWdoRevOpen;
	const ratingData = isAllRev ? v.allRatingData : v.ratingData;
	const reviewsNode = v.isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const reviewsHead = $dqs("#reviews-head", reviewsNode);
	let typeClass = "",
		headCnt = "";

	if (v.isWdoRevOpen && reviewsHead) {
		reviewsHead.innerHTML +=
			'<div class="window-close">' +
			svg.close(userSetting.font_color) +
			"</div>";
	}

	if (!ratingData) {
		return;
	}

	var headSortHtml = "";
	// QA未开启时，或者是全部评论的弹窗，嵌入旧排序部件
	if (v.userSetting.is_qa_active !== 1 || v.isWdoRevOpen || v.isMixReview) {
		headSortHtml = `<div class="head-sort" ><div class="sort-content">
		<div class="sort-icon"><svg width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M17.905 8.274H7.675a2.609 2.609 0 0 0-5.018 0H.714a.714.714 0 0 0 0 1.429h1.943a2.609 2.609 0 0 0 5.018 0h10.23a.714.714 0 0 0 0-1.429zM5.165 10.17a1.181 1.181 0 1 1-.001-2.363 1.181 1.181 0 0 1 .002 2.363zm12.74-8.296h-1.381a2.609 2.609 0 0 0-5.006 0H.718a.714.714 0 0 0 0 1.428h10.79a2.609 2.609 0 0 0 5.027 0h1.372a.714.714 0 1 0-.002-1.428zM14.02 3.79a1.18 1.18 0 1 1 .003 0h-.003zm3.885 10.886h-3.344a2.609 2.609 0 0 0-5.019 0H.718a.714.714 0 0 0 0 1.429h8.824a2.61 2.61 0 0 0 5.019 0h3.344a.714.714 0 0 0 0-1.429zm-5.853 1.895a1.181 1.181 0 1 1-.003-2.363 1.181 1.181 0 0 1 .003 2.363z"
					fill="${userSetting.font_color}" />
			</svg>
		</div>
		${sortPopupHtml().html}
		</div></div>`;
	}

	//常规评论区
	// if (!isMixReview && !isWdoRevOpen) {
	// 构造评论区头部
	var starTag = getTotalStarString(ratingData.rating, true);
	let reviewCount = ratingData.total_reviews;

	let headButtonHtml = ``,
		writeQABtnHtml = "";
	if (userSetting.is_show_review_button !== 2 && !isAllRev) {
		headButtonHtml = `<button class="head-button tt-write-reviews" type="button">
          ${lang.write_reviews}
      </button>`;
	}
	// 开启QA，而且不是弹窗评论区和全部评论区，嵌入写问题按钮
	if (v.userSetting.is_qa_active === 1 && !v.isWdoRevOpen && !v.isMixReview) {
		writeQABtnHtml = `<button class="head-button tt-write-qa" type="button">
				${lang.question_and_answer.ask_a_question}
		</button>`;
	}

	let headCenterHtml = "",
		headLeftHtml = "",
		headTitleHtml = "",
		headRating = `<p class="point">
          <span class="big-point">${ratingData.rating}</span>
        </p>`;
	if (revHeadType === 1) {
		headTitleHtml = `<p class="tt-head-title">${lang.customer_reviews}</p>`;
		headCenterHtml = `<div class="head-center">
      <div class="star-raw"><span class="star-classify">
        <span class="number">5</span> ${lang.star}</span><span class="star-range"></span><span class="star-num"></span>
        </div>
      <div class="star-raw"><span class="star-classify">
        <span class="number">4</span> ${lang.star}</span><span class="star-range"></span><span class="star-num"></span>
        </div>
      <div class="star-raw"><span class="star-classify">
        <span class="number">3</span> ${lang.star}</span><span class="star-range"></span><span class="star-num"></span>
        </div>
      <div class="star-raw"><span class="star-classify">
        <span class="number">2</span> ${lang.star}</span><span class="star-range"></span></span><span class="star-num"></span>
        </div>
      <div class="star-raw"><span class="star-classify">
        <span class="number">1</span> ${lang.star}</span><span class="star-range"></span></span><span class="star-num"></span>
        </div>
      </div>`;
	} else if (revHeadType === 2) {
		// if (!v.isMixReview) {
		typeClass = "review-head-type2";
		// }
	}

	headLeftHtml += `
      <div class="product-rating">
        ${headRating}
        <div class="star vstar-star">
            ${starTag}                                      
        </div>
        <p class="reviews-num"> ${reviewCount} ${lang.reviews}</p>
      </div>
      `;
	headLeftHtml = `<div class="head-left">
      ${headLeftHtml}
    </div>`;
	headCnt = `
	${headTitleHtml}
	<div class="tt-head-content" style="
	">
		<div class="tt-head-left">
		${headLeftHtml + headCenterHtml}
		${
			v.userSetting.is_show_album === 1 && revHeadType === 1
				? '<div class="tt-reviews-album"></div>'
				: ""
		}
		</div>
		
		<div class="tt-head-right">
				${headButtonHtml + writeQABtnHtml + headSortHtml}
		</div>
	</div>

	`;

	if (reviewsHead) {
		reviewsHead.className += typeClass;
		reviewsHead.innerHTML += headCnt;

		if (!(revHeadType === 2)) {
			const range = $dqsa(".star-range", reviewsHead);
			const reviewsNum = $dqsa(".star-num", reviewsHead);

			range.forEach((item, index) => {
				var starIndex = 5 - index;
				const ratingCount = ratingData["total_star" + starIndex];
				reviewsNum[index].textContent = ratingCount;
				var percentage = (
					(ratingCount / ratingData.total_reviews) *
					100
				).toFixed(2);
				percentage += "%";
				item.setAttribute("index", starIndex);
				item.style.cssText = `background:linear-gradient(to right, ${userSetting.star_color} 0%, ${userSetting.star_color} ${percentage},${userSetting.nostar_color} ${percentage}, ${userSetting.nostar_color})`;
				if (ratingCount > 0 && v.userSetting.rating_filter === 1) {
					item.onclick = function () {
						const rating = item.getAttribute("index");
						if (rating === reviews.ratingFilter) {
							reviews.ratingFilter = 0;
						} else {
							reviews.ratingFilter = rating;
						}
						sgPage(3, 1);
						setReviewBody(false, true);
					};
					item.parentNode.onmouseover = function () {
						$dqs(".head-center", reviewsHead).classList.add("tt-hover");
						this.classList.add("active");
					};
					item.parentNode.onmouseout = function () {
						$dqs(".head-center", reviewsHead).classList.remove("tt-hover");
						this.classList.remove("active");
					};
				}
			});
			if (v.userSetting.is_show_album === 1) {
				insertBuyersShow($dqs(".tt-reviews-album", reviewsHead));
			}
		}
	}
}

export { setReviewHead };

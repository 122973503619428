import { $dqs } from "../common/utils";
import v from "../common/variable";
import { insertReviewDetail, processRecordedData } from "../page/page-common";
import { getReviews } from "./reviews";

let wdoRevMask = null;

//Mark 嵌入边栏标签
function insertRevTab() {
	const sty = v.isCssLoaded ? "" : 'style="display:none"';
	let tab = `<div id="vstar-tab" ${sty} >${v.userSetting.sidetab_setting.sidetab_button_text}</div>`;

	// let close = `<div  class="window-close"><svg style="width:20px;height:20px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
	// <path d="M6.99968 5.95621L1.25984 0.216371C1.12066 0.0819462 0.934254 0.00756417 0.740764 0.00924555C0.547273 0.0109269 0.362184 0.0885372 0.225361 0.225361C0.0885372 0.362184 0.010927 0.547273 0.00924558 0.740764C0.0075642 0.934254 0.0819463 1.12066 0.216371 1.25984L5.95547 6.99968L0.216371 12.7395C0.147807 12.808 0.0934101 12.8894 0.056285 12.9789C0.01916 13.0685 3.431e-05 13.1644 4.612e-08 13.2614C-3.42178e-05 13.3583 0.0190236 13.4543 0.0560853 13.5439C0.0931471 13.6334 0.147487 13.7148 0.216002 13.7834C0.284518 13.8519 0.365867 13.9063 0.455405 13.9435C0.544944 13.9806 0.640917 13.9997 0.737847 13.9997C0.834777 13.9998 0.930764 13.9807 1.02033 13.9437C1.10989 13.9066 1.19128 13.8523 1.25984 13.7837L6.99968 8.04316L12.7395 13.7837C12.8081 13.8523 12.8895 13.9067 12.9791 13.9438C13.0687 13.9809 13.1647 14 13.2616 14C13.3586 14 13.4546 13.9809 13.5442 13.9438C13.6338 13.9067 13.7152 13.8523 13.7837 13.7837C13.8523 13.7152 13.9067 13.6338 13.9438 13.5442C13.9809 13.4546 14 13.3586 14 13.2616C14 13.1647 13.9809 13.0687 13.9438 12.9791C13.9067 12.8895 13.8523 12.8081 13.7837 12.7395L8.04316 6.99968L13.7837 1.25984C13.9221 1.12137 13.9998 0.933605 13.9997 0.737847C13.9997 0.542089 13.9218 0.354375 13.7834 0.216002C13.6449 0.0776292 13.4571 -6.91476e-05 13.2614 4.61754e-08C13.0656 6.92399e-05 12.8779 0.0779003 12.7395 0.216371L6.99968 5.95547V5.95621Z" fill="
	// ${v.userSetting.font_color}"/>
	// </svg>
	// </div>`
	let window = `<div class="mask window-review-mask" style="display:none">
  <div id="vstar-window-review"></div></div>`;
	!$dqs("#vstar-tab") && document.body.insertAdjacentHTML("beforeend", tab);
	if (!$dqs("#window-review-mask")) {
		document.body.insertAdjacentHTML("beforeend", window);
		wdoRevMask = $dqs(".window-review-mask");
		wdoRevMask.onclick = function (e) {
			if (!$dqs("#vstar-window-review").contains(e.target)) {
				closeAllReviewWindow();
			}
		};
	}

	$dqs("#vstar-tab").onclick = function () {
		v.isWdoRevOpen = true;
		document.body.classList.add("trustoo-open");
		wdoRevMask = $dqs(".window-review-mask");
		wdoRevMask.style.display = "block";
		if (v.isProPage) {
			processRecordedData("reviews_click");
		}

		if (!v.isWdoRevExist) {
			v.wdoRev.node = $dqs("#vstar-window-review");

			if (v.isActWdoRev) {
				let tarInfo = {};
				tarInfo.tarNode = v.wdoRev.node;
				tarInfo.position = "afterbegin";
				getReviews(tarInfo);
				insertReviewDetail();
			}
		}
	};
}

function addCloseWdoEvent() {
	$dqs(".window-close", v.wdoRev.node).onclick = function () {
		closeAllReviewWindow();
	};
}

// 嵌入弹窗评论区
function insertWdoRev(review) {
	if (review) {
		$dqs(".window-review-mask").insertAdjacentHTML("afterbegin", review);
	}
}

function closeAllReviewWindow() {
	v.isWdoRevOpen = false;
	wdoRevMask.style.display = "none";
	document.body.classList.remove("trustoo-open");
}

export { insertRevTab, insertWdoRev, addCloseWdoEvent };
